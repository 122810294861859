<template>
      <el-container v-if="isCanApp == false" class="box">
        <p>抱歉！此站点已暂停服务！</p>
    </el-container>
    <el-container v-else>
        <el-main style="margin-top: 30px;">
            <el-row :gutter="20" type="flex" justify="center">
                <el-col :span="20">
                    <div class="grid-header">
                        <el-carousel indicator-position="outside">
                            <el-carousel-item v-for="item in imgs" :key="item"><img :src="getImagePath(item)" width="100%"/></el-carousel-item>
                        </el-carousel>
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="20" type="flex" justify="center">
                <el-col :span="20">
                    <div class="grid-content bg-purple">
                        <div style="text-align: left; min-height: 100px;">
                            <span>
                                <strong>提示: </strong><br>
                                <span class="tips">
                                    ❶ 如遇卡住30秒不动、划掉后台从新进入、出现频繁请点击查码、查询后点击复制链接到浏览器打开下载即可;<br>
                                    ❷ 一个授权码下载一开、两个授权码下载2开、
                                </span>
                            </span>
                            <br><br>
                            <span v-if="!show" :style="{'color':color}" v-cloak>{{ message }}</span>
                        </div>
                        <el-form ref="formData" :model="formData" :rules="rules" size="medium" label-width="100px">
                            <el-form-item label="应用" prop="chooseApp">
                                <el-select @change='available' v-model="formData.chooseApp" placeholder="请选择APP" clearable :style="{width: '95%'}">
                                    <el-option v-for="(item, index) in chooseAppOptions" :key="index" :label="item.name" :value="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="授权码" prop="authcode">
                                <el-input v-model="formData.authcode" placeholder="请输入授权码" clearable :style="{width: '95%'}"></el-input>
                            </el-form-item>
                            <el-form-item label="" prop="enter">
                                <el-button type="primary" icon="el-icon-success" size="medium" @click="enterAvailable" v-cloak> 确认 </el-button>
                                <el-button type="primary" icon="el-icon-warning" size="medium" @click="checkVisitCode" v-cloak> 查询 </el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <ActiveCode @close-draw="closeDraw" :appName = "appName" :drawer="drawer" :redeemUsedAt="redeemUsedAt" :redeemStatus="redeemStatus" :authcode="authcode" :tfInfo="tfInfo"></ActiveCode>
                </el-col>
            </el-row>
        </el-main>
    </el-container>
</template>
<script>
import ActiveCode from '@/components/ActiveCode.vue';
import {formatDate,getQueryString} from "@/utils/common"
import {getAppInfo,activateApp} from "@/utils/request";
export default {
    components: {
        ActiveCode
    },
    name: 'App',
    data() {
        return {
            show:false,
            message:"",
            color:"",
            formData: {
                chooseApp: "",
                authcode: "",
                enter: undefined,
            },
            rules: {
                chooseApp: [{ required: true, message: '请选择APP', trigger: 'change' }],
                authcode: [{ required: true, message: '请输入授权码', trigger: 'blur' }],
            },
            chooseAppOptions: [], // 应用列表
            imgs: [
                "bg.jpeg",
            ],
            authcode:'',
            redeemStatus: '',
            redeemUsedAt: '',
            tfInfo: {
                tf1_icon:'',
                tf1_link:'',
                tf1_name:'',
                tf2_icon:'',
                tf2_link:'',
                tf2_name:'',
                tf3_icon:'',
                tf3_link:'',
                tf3_name:'',
                active_time:0,
            },
            drawer: false,
            isCanApp:true,
            appName:"",
        }
    },
    methods: {
        initApp: function() {},
        closeDraw: function() {
            this.drawer = false
        },
        getImagePath(filename) {
            return require(`@/assets/imgs/${filename}`);
        },
        available: function() {
            this.$refs.formData.validate((valid) => {
                if (!valid) return
            })
            this.appName = this.formData.chooseApp
            if (this.appName == '') {
                return
            }
            getAppInfo(this)
        },

        // 获取应用列表
        getAppList: function(code) {
            this.$http.post('down/applist/'+code, { }).then((response) => {
                this.chooseAppOptions = response.data.data;
            }).catch(() => alert('发生错误'))
        },

        // 激活
        enterAvailable: function() {
            activateApp(this)
        },

        checkVisitCode: function() {
            this.$refs.formData.validate((valid) => {
                if (!valid) return
            })
            this.appName = this.formData.chooseApp; // 应用ID
            const vKey = this.formData.authcode; // 授权码
            if (this.appName === '' || vKey === '') {
                return
            }
            this.$http.get('down/view/'+this.appName+'/code/'+vKey).then(response => {
                var data = response.data
                if (data.code === 1) {
                    this.drawer = true;
                    //查询app信息
                    this.redeemStatus = data.data.state == 1 ? "已激活" : data.data.state === 0 ? "未激活" : "已冻结";
                    this.authcode = vKey;
                    this.redeemUsedAt = formatDate(data.data.active_time);
                    this.tfInfo = data.data;
                }else {
                    this.$message.error(data.msg)
                    this.initApp();
                }
            }).catch(error => {
                this.$message.error(error.response.data.msg)
                this.initApp();
            }).finally(() => this.loading = false)
        },
    
    },
    mounted() {
        var code = getQueryString('c');
        if (code == null || code == '') {
            this.isCanApp = false
            return ;
        }
        this.getAppList(code);
    }
}
</script>
<style src="@/assets/css/index.css"></style>